import { onBeforeUnmount, onMounted, type Ref, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

export function useDefaultForm() {
  const form = ref(null) as Ref<HTMLFormElement | null>;

  const successMessage = ref('');
  const errorMessage = ref('');
  const isProcessing = ref(false);
  const hasFormChanged = ref(false);

  onMounted(() => {
    if (form.value !== null) {
      form.value?.addEventListener('change', setFormAsChanged);
    }
  });

  onBeforeUnmount(() => {
    if (form.value !== null) {
      form.value?.removeEventListener('change', setFormAsChanged);
    }
  })

  const setFormAsChanged = () => {
    if (!hasFormChanged.value) {
      hasFormChanged.value = true;
    }
  }

  function onInvalidSubmit() {
    if (form.value !== null) {
      const fieldErrors = form.value.querySelectorAll('.has-error');

      if (fieldErrors[0]) {
        const errorFieldGroup = fieldErrors[0].closest('.field-group') as HTMLElement;
        const siteHeader = document.getElementById('site_header');
        if (siteHeader !== null && errorFieldGroup !== null) {
          const scrollTo = errorFieldGroup.offsetTop - siteHeader.offsetHeight;
          window.scrollTo({ top: scrollTo, behavior: 'smooth' });
        }
      }
    }
  }

  onBeforeRouteLeave((to, from) => {
    if (hasFormChanged.value && !isProcessing.value) {
      const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!'
      )
      if (!answer) return false
    }
  });

  return {
    form,
    hasFormChanged,
    successMessage,
    errorMessage,
    isProcessing,
    onInvalidSubmit
  }
}
